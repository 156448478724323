import { currentViewLightboxAtom } from '@components/unified-lightbox/atoms';
import type { Media, ReviewMedia } from '@typings/vendor/properties';
import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import Styles from './ReviewImageIcon.scss';

export type ReviewImageIconProps = {
	media: Media;
};

const isReviewMedia = (media: Media): media is ReviewMedia => {
	return (
		media.mediaType === 'PHOTO' &&
		'reviewId' in media &&
		'reviewRating' in media &&
		'reviewCreatedDate' in media &&
		'reviewAuthorFirstname' in media &&
		'reviewAuthorLastname' in media &&
		'reviewContent' in media
	);
};

const ReviewImageIcon: FC<ReviewImageIconProps> = (props) => {
	const { media } = props;
	const isLightboxOpen = useAtomValue(currentViewLightboxAtom);

	if (!isReviewMedia(media) || isLightboxOpen) {
		return null;
	}

	const reviewerName = `${media.reviewAuthorFirstname} ${media.reviewAuthorLastname[0]}.`;

	return (
		<>
			<div className={Styles.container}>
				<div className={Styles.iconContainer}>
					<Icon
						size="sm"
						className={Styles.starFillingColor}
						name="star_filled"
					/>
				</div>
				<div className={Styles.textContainer}>
					<Body2 bold className={classNames(Styles.reviewText)}>
						{media.reviewRating.toPrecision(2)}
					</Body2>
					<Body2
						bold
						className={classNames(Styles.reviewText, Styles.hideOnMobile)}
					>
						{' · '}
					</Body2>
					<Body2 className={classNames(Styles.reviewText, Styles.hideOnMobile)}>
						{reviewerName}
					</Body2>
				</div>
			</div>
		</>
	);
};

export default ReviewImageIcon;
