import {
	computedMediaAtom,
	currentFilterAtom,
	currentIndexAtom,
	nextMediaAtom,
	prevMediaAtom,
} from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useScreenDetails } from '@hooks/useScreenDetails';
import type { Viewport } from '@typings/redux';
import noop from '@utils/noop';
import { IconButton } from '@xo-union/tk-component-icons';
import { CarouselWithStaticVisibleSlots } from '@xo-union/ui-carousel';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { type FC, useCallback } from 'react';
import { connect } from 'react-redux';
import Media from '../../../../pages/Storefront/components/Media';
import Playable, {
	type PlayableRenderProps,
} from '../../../../pages/Storefront/components/Media/playable';
import { checkIsTablet } from '../../../../pages/Storefront/containers/InlineRFQ/utils';
import ReviewDetails from '../ReviewDetails/ReviewDetails';
import Styles from './Carousel.scss';
import { useLightboxKeydown } from './hooks/use-lightbox-keydown';
import { getImageStyles, getLightboxWrapperStyles } from './utils';

interface StateProps {
	vendor: Vendor.Decorated;
	vendorRaw: Vendor.Raw | null;
	viewport: Viewport;
}

export type CarouselProps = StateProps;

const Carousel: FC<CarouselProps> = (props) => {
	const { vendor, vendorRaw, viewport } = props;
	const currentFilter = useAtomValue(currentFilterAtom);
	const isReviewsLightbox = currentFilter === 'reviews';

	const carouselMedia = useAtomValue(computedMediaAtom);
	const currentIndex = useAtomValue(currentIndexAtom);
	const prevImage = useSetAtom(prevMediaAtom);
	const nextImage = useSetAtom(nextMediaAtom);

	const [isLandscape, width, height] = useScreenDetails();
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isTabletOrMedium && !isMobile;

	useLightboxKeydown();
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const onNavigationChange = useCallback(
		(index, clickEvent = false) => {
			if (currentIndex < index) {
				trackUnifiedLightbox(
					AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
					clickEvent
						? AnalyticsConstants.CLICKED_RIGHT
						: AnalyticsConstants.SWIPE_RIGHT,
					AnalyticsConstants.PHOTO_DETAIL_VIEW,
				);
				nextImage(noop);
			} else {
				trackUnifiedLightbox(
					AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
					clickEvent
						? AnalyticsConstants.CLICKED_LEFT
						: AnalyticsConstants.SWIPE_LEFT,
					AnalyticsConstants.PHOTO_DETAIL_VIEW,
				);
				prevImage(noop);
			}
		},
		[trackUnifiedLightbox, nextImage, prevImage, currentIndex],
	);

	const useCreateClick = (action: 'clicked right' | 'clicked left') => {
		return useCallback(() => {
			onNavigationChange(
				currentIndex + (action === 'clicked right' ? 1 : -1),
				true,
			);
		}, [action]);
	};

	const NextButton = () => {
		const onClick = useCreateClick('clicked right');

		return (
			<div
				className={Styles.navigationIconWrapper}
				aria-roledescription="next button"
			>
				<IconButton
					className={classNames(Styles.nextButton, {
						[Styles.hidden]: currentIndex >= carouselMedia.length - 1,
					})}
					name="caret_right"
					onClick={onClick}
					size="md"
				/>
			</div>
		);
	};

	const PrevButton = () => {
		const onClick = useCreateClick('clicked left');

		return (
			<div
				className={Styles.navigationIconWrapper}
				aria-roledescription="previous button"
			>
				<IconButton
					className={classNames(Styles.previousButton, {
						[Styles.hidden]: currentIndex <= 0,
					})}
					name="caret_left"
					onClick={onClick}
					size="md"
				/>
			</div>
		);
	};

	return (
		<CarouselWithStaticVisibleSlots
			navigationIndex={currentIndex}
			onNavigationIndexChange={onNavigationChange}
			renderNextButton={NextButton}
			renderPrevButton={PrevButton}
		>
			{carouselMedia.map((media) => {
				const isSingleItem = carouselMedia.length === 1;

				const { image } = getImageStyles(media, isReviewsLightbox);
				const isQuickResponder = Boolean(
					vendorRaw?.vendorBehavior?.quickResponder,
				);

				return (
					<div
						className={getLightboxWrapperStyles(media, isQuickResponder)}
						key={media.id}
					>
						<Playable
							media={media}
							render={({ playMedia }: PlayableRenderProps) => {
								const { mediaType } = media;
								if (
									isSingleItem &&
									(mediaType === '360TOUR' ||
										mediaType === 'VIDEO' ||
										mediaType === 'VIDEO_UPLOAD')
								) {
									playMedia();
								}
								return (
									<>
										<Media
											canPlay
											mediaItem={media}
											imageClass={image}
											width={width}
											height={height}
											fit
											tabIndexOverride={-1}
										/>
										{!isDesktop && isReviewsLightbox && <ReviewDetails />}
									</>
								);
							}}
						/>
					</div>
				);
			})}
		</CarouselWithStaticVisibleSlots>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
	vendorRaw: state.vendor.vendorRaw,
	viewport: state.viewport,
});

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export default connect<StateProps, {}>(mapStateToProps)(Carousel);
