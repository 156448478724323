import type { Dispatch, SetStateAction } from 'react';
import type { Gallery } from '../../../../../../types/reviews';
import { CONSTANTS } from '../constants';

export interface CalculateDisplayCounterParams {
	element: HTMLDivElement | null;
	gallery: Gallery[];
	isDesktop: boolean;
	setAdditionalImageCount: Dispatch<SetStateAction<number>>;
	setViewableImages: Dispatch<SetStateAction<number>>;
}

const calculateDisplayCounter = ({
	element,
	gallery,
	isDesktop,
	setAdditionalImageCount,
	setViewableImages,
}: CalculateDisplayCounterParams) => {
	if (element) {
		const imageWidth = isDesktop
			? CONSTANTS.desktop.imageWidth
			: CONSTANTS.mobile.imageWidth;
		const divWidth = element.clientWidth;
		const numberOfImages = gallery.length;
		const numberOfViewableImages = Math.floor(
			divWidth / (imageWidth + CONSTANTS.imageMargin),
		);
		const displayCounter = numberOfImages - numberOfViewableImages + 1;
		setViewableImages(numberOfViewableImages);
		setAdditionalImageCount(displayCounter);
	}

	return null;
};

export { calculateDisplayCounter };
