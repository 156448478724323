import { selectAssignment } from '@redux/experiments/selectors/index';
import type { ReviewFormLoginFlowAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const reviewFormLoginFlowAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'reviewFormLoginFlow',
	]) as ReviewFormLoginFlowAssignment;
};

export const isReviewFormLoginFlowVariantSelector = (state: State) => {
	const assignment = reviewFormLoginFlowAssignmentSelector(state);
	return assignment === 'variant1';
};
